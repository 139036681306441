import * as React from "react";
import './url.scss'
import Url from "url-parse";

const URL = ({url, label}: { url: string, label?: string }) => {
    function shortenUrl(): string {
        if (url) {
            const parsedUrl = new Url(url);
            return parsedUrl.hostname
        } else {
            return "";
        }
    }

    return (
        <a className="url" target="_blank" rel="noopener noreferrer" href={url}>{label || shortenUrl()}</a>
    );
};

export default URL;
